import Vue from 'vue'
import AudioVisual from 'vue-audio-visual'
import TutorialPlayer from './tutorial/player/index'
import WaveFormPlayer from './waveform/index'
import SearchHeader from './search/header/index'
import SearchResults from './search/results/index'
import VueKonva from 'vue-konva'

Vue.use(VueKonva, { prefix: 'k'});
Vue.use(AudioVisual)

console.log('main')
$('.tutorial-player').each(function () {
    var $el = $(this)
    var tutorialId = $el.attr('data-id')
    var props =  {'id':tutorialId}
    var id = $el.attr('id')
    new Vue({
        'el': `#${id}`,
        'render': (h) => {
            return h(TutorialPlayer,{props})
        }
    });
});


$('.wave-form').each(function () {
    var $el = $(this)
    var tutorialId = $el.attr('data-id')
    var props =  {'id':tutorialId}
    var id = $el.attr('id')
    new Vue({
        'el': `#${id}`,
        'render': (h) => {
            return h(WaveFormPlayer,{props})
        }
    });
});


$('.vue-search-header').each(function() {
    var $el = $(this)
    var id = $el.attr('id')
    var $el = $(this)
    new Vue({
        'el': `#${id}`,
        'render': (h) => {
            return h(SearchHeader,{})
        }
    });
})


$('.vue-search-results').each(function() {
    var $el = $(this)
    var id = $el.attr('id')
    const props = $(this).data()
    new Vue({
        'el': `#${id}`,
        'render': (h) => {
            return h(SearchResults,{props})
        }
    });
})
