<template>
   <div v-if="query != null">
        <div class='d-flex flex-row bar'>
           <h3 v-if='text'>{{text}}</h3>
           <h3 v-else><span>Results for:</span>{{query}}</h3>
        </div>
        <tutorial-player v-for="item in tutorials" :key="item.id" :item='item' />
   </div>
</template>

<script>

import { getTutorials } from '../../services/tutorial'
import { EventBus, EVENT_SEARCH_QUERY_CHANGED } from '../../services/event-bus'

import TutorialPlayer from '../../tutorial/player/index'

export default {
  
  components: {
    TutorialPlayer
  },
  props: [
    'iquery',
    'itext'
  ],
  created() {
    EventBus.$on(
        EVENT_SEARCH_QUERY_CHANGED,
        this.onQueryChanged
     )
   },
   mounted() {
    if (this.iquery != null) {
      this.onQueryChanged({query:this.iquery, text:this.itext})
    }
   },
   data() {
       return {
           query: null,
           tutorials: [],
           text:null,
           texts: []
       }
   },
   
   methods: {
       onQueryChanged(payload) {
          this.query = payload.query
          this.text = payload.text
          getTutorials(this.query).then(this.onGetTutorials)
       },
       onGetTutorials(resp) {
         this.tutorials = resp.results
       }
   }

 }
</script>