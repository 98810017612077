<template>
    <div class="d-flex flex-column tutorial-player my-2" style="min-height:300px " ref='container'>
        <div  v-if="player()" class="d-flex justify-content-between tutorial-player-header align-items-center">
          <div class='info'>
              <div class='title'>{{tutorial.name}}</div>
          </div>
          <a :href='getUrl()'>
            <i class="far fa-share-square"></i>
          </a>
        </div>

        <audio  ref='player' >
            <source  v-if="audio"  :src="audio.url"  type="audio/mp3">
        </audio>
        <k-stage v-if="audio" :config="configStage()">
            <k-layer>
                <k-circle ref='ball' :config="configBall()"></k-circle>
                <k-circle v-for="(item, index)  in samples" :key="index" :config="configCircle(index,item)"></k-circle>
                <k-line :config="configLine()"/>
            </k-layer>
        </k-stage>
        <div  v-if="player()" class="d-flex justify-content-between tutorial-player-footer align-items-center">
            <div v-if="audios && audio">
                <span class='light upper'>{{speaker.code }}</span>    <span class='dark pr-2'> - ({{ audioIndex + 1 }} /  {{audios.length}} )</span>
            </div>
        <a  @click="togglePlay()" class='play'>
                <i v-if="paused" class='fa fa-play'></i>
                <i v-else class='fa fa-stop'></i>
          </a>
          <div class='controls d-flex flex-row'>
                <a :class="classBtnPrev()"  @click="prev()"><i class='fa fa-step-backward'></i></a>
                <span class="speaker mx-4">{{speaker.code }}</span>
                <a :class="classBtnNext()" @click="next()"><i class="fas fa-forward"></i></a>
           </div>
        </div>
    </div>
</template>
<script>

    import { getTutorial } from "../../services/tutorial"
    
    export const TUTORIAL_URL_PATTERN = "/language/tutorial/"

    export default {
        name: "TutorialPlayer",
        props: ["id", "item"],
        data() {
            return  {
                loading:true,
                tutorial:null,
                status:0,
                audioIndex:0,
                audios:[],
                padding: 20,
                paused: true,
                playing: false,
                animBall: null,
                padding:5,
                lineColor:'#6F6F6F',
                dotColor:'#6F6F6F',
                ballColor:'#F3F3F3',
                
            }
        },
        mounted() {
            if (this.item) {
                this.onLoad(this.item) 
            } else if (this.id){
                getTutorial(this.id).then(this.onLoad)
            }
        },
        computed: {
            width: function () {
                return this.$refs.container.clientWidth
            },
            height: function () {
                return this.$refs.container.clientHeight
            },
            audio: function() {
                return this.audios[this.audioIndex]
            },
            max: function() {
                return this.audios[this.audioIndex].analysis.data.max_y
            },
            min: function() {
                return this.audios[this.audioIndex].analysis.data.min_y
            },
            samples: function() {
                return this.audios[this.audioIndex].analysis.data.samples
            },
            speaker: function() {
                return this.audios[this.audioIndex].speaker
            }
        },
        methods: {
            getUrl() {
                return TUTORIAL_URL_PATTERN + this.tutorial.id
            },
            onLoad(tutorial) {
                this.tutorial = tutorial
                this.audios = this.tutorial.audios
                this.loading = false
                this.player().onplay = this.onPlay
                this.player().onpause =this.onPause
                this.player().onended = this.onEnded
            },
            classBtnDefault() {
                return []
            },
            classBtnPrev() {
                let classes = this.classBtnDefault()
                if (!this.hasPrev()) {
                    classes.push('disabled')
                }
                return classes
            },
            classBtnNext() {
                let classes = this.classBtnDefault()
                if (!this.hasNext()) {
                    classes.push('disabled')
                }
                return classes
            },
            onEnded() {

                if (this.audios.length >= this.audioIndex) {
                    this.audioIndex = this.audioIndex + 1
                    
                    if (typeof this.audios[this.audioIndex] == 'undefined'){
                        this.audioIndex = 0
                    }else{
                        this.player().pause();
                        this.player().load();
                        this.player().play()
                    }
                    
                    
                } else{
                    this.audioIndex = 0
                    this.player().pause();
                    this.player().load();
                }
            },
            configStage() {
                return { 
                    'width': this.width, 
                    'height': this.height + this.padding*2
                }
            },
            /*   Ball Animation   */
            ball() {
                return this.$refs.ball.getNode()
            },
            configMax() {
                const sample = this.samplePoint(2,this.max)
                return {
                    fill: 'red',
                    strokeWidth: 0,
                    radius: 10,
                    x: sample.x,
                    y: sample.y,
                }
            },
            configMin() {
                const sample = this.samplePoint(2,this.min)
                return {
                    fill: 'green',
                    strokeWidth: 0,
                    radius: 10,
                    x: sample.x,
                    y: sample.y
                }
            },
            configBall() {
                return {
                    fill: this.ballColor,
                    strokeWidth: 0,
                    radius: 5,
                    x: -100,
                    y: -100
                }
            },
            getAnimBall() {
                if (!this.animBall) {
                    const ball =  this.ball()
                    this.animBall = new Konva.Animation(
                        this.animBallCallback, 
                        ball.getLayer()
                    );
                }
                return this.animBall
            },
            getIndex() {
                return  Math.round(
                    this.samples.length * this.getRelativeDuration()
                )
            },
            getRelativeDuration() {
                return this.player().currentTime / this.player().duration
            },
            animBallCallback(frame) {
                const index = this.getIndex()
                if (
                    index < this.samples.length
                    && this.samples[index]
                
                ) {
                    const point = this.samplePoint(
                        index,
                        this.samples[index]
                    )
                    if (point.x  && point.y ) {
                        this.ball().setX(point.x);
                        this.ball().setY(point.y);
                        return 
                    }

                }
                this.ball().setX(-100);
                this.ball().setY(-100);
                
            },

            startAnimBall() {  
                this.getAnimBall().start();
            },
       
            /*   Player   */

            player() {
                return this.$refs.player
            },
            
            onPlay() {
                this.getAnimBall().start()
                this.paused = false
            },
            onPause() {
                this.getAnimBall().stop()
                this.paused = true
            },
            hasPrev() {
                return this.audioIndex > 0
            },
            hasNext() {
               
                return this.audioIndex <= this.audios.length - 1 
            },
            prev() {
                if (this.hasPrev()) {
                    this.audioIndex =  this.audioIndex - 1
                }
            },
            next() {
                console.log(this.audios[this.audioIndex])
                if (this.hasNext()) {
                    
                    this.audioIndex =  this.audioIndex + 1
                    
                }
            },
            togglePlay() {
                if (this.isPaused()) {
                    this.player().play()
                    this.paused = false
                } else{
                    this.player().pause()
                    this.paused = true
                }
            },
            isPaused() {
                return this.player() && this.player().paused
            },
            
            /*   Background   */

            samplePoint(index, sample) {
                let x = index * this.width / this.samples.length
                // self.rects()['path'][3] - self.rects()['path'][3] * \
                // (x - float(self.min_y)) / (float(self.max_y) - float(self.min_y))
                let y =  this.padding + this.height - this.height * (sample - this.min) / (this.max - this.min) 
                return {
                    x,y
                }    
            },
            sampleStyle() {
                return {
                    fill: this.dotColor,
                    strokeWidth: 0,
                    radius: 3
                }
            },
            configCircle(x,y) {
                let circle = null
                if (y) {
                    circle  = {
                        ...this.samplePoint(x,y),
                        ...this.sampleStyle()
                    }
                }
                return circle
            },
            points() {
                let points = []
                for (
                    let i = 0;
                    i <= this.samples.length;
                    i++) {
                        if (this.samples[i]) {
                            let point = this.samplePoint(i, this.samples[i])
                            points.push(point.x)
                            points.push(point.y)
                        }
                       
                    }
                return points
            },
            configLine() {
                let line = {
                    points: this.points(),
                    tension: 0.5,
                    closed: false,
                    stroke: this.lineColor
                }
                return line
            },

            /* End   Background   */
            configEgde(edge) {
                let x,y = 0
                let fill = "black" 
                let radius = 4
                switch(edge) {
                    case 'tl':
                        x = 0
                        y = 0
                        fill = 'red'
                        break
                    case 'bl':
                        x = this.width
                        y = this.height - 2
                        fill = 'green'
                        break
                }
                return {
                    x,
                    y,
                    radius,
                    fill,
                    strokeWidth: 0
                }
            }
        }
    }
</script>