import { EVENT_SEARCH_QUERY_CHANGED } from "./event-bus";


export function getTutorial(id, search=null) {
    // tid is an id or public or assistant
    let url = `/api/tutorials/${id}`
    if (search) {
        url = `${url}?search=${search}`
    }
    
    return fetch(url, {
        'credentials': 'include',
        'method': 'GET'
    }).then((response) => {
        return response.json();
    })
}

export function getTutorials(search) {
    // tid is an id or public or assistant
    let  url = `/api/tutorials`
    if (search) {
        url = `${url}?search=${search}`
    }
    return fetch(url, {
        'credentials': 'include',
        'method': 'GET'
    }).then((response) => {
        return response.json();
    })
}