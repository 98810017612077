<template>
    <av-waveform v-if='url' :audio-src="url"></av-waveform>
</template>

<script>
import {getTutorial} from "../services/tutorial"

export default {
    name: "WaveFormPlayer",
    props: ["id"],
    data() {
        return  {
            url:null
        }
    },
    mounted() {
        getTutorial(this.id).then(this.onLoad)
    },
    methods: {
        onLoad(resp) {
            this.url = resp.audios[0].url
        },


    }
}

</script>