<template>
     <div class="input-group">
        <input type="text" v-model="query"  class="form-control" placeholder="Search..." aria-describedby="basic-addon2">
        <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
        </div>
    </div>
</template>
<script>

import {
    EventBus,
    EVENT_SEARCH_QUERY_CHANGED
} from "../../services/event-bus"

export default {
    
    mounted() {
      console.log('this is current player instance object')
      var searchParams = new URLSearchParams(window.location.search);
        
      if (searchParams.has('query')) {
        this.query = searchParams.get('query')
      }
    },
    data() {
        return {
            query:''
        }
    },
    watch: {
        // whenever question changes, this function will run
        query: function (newQuery, oldQuery) {
            EventBus.$emit(EVENT_SEARCH_QUERY_CHANGED, {'query':newQuery})
        }
    },
  }
</script>